import {User} from '@hconnect/apiclient'
import {getDateRangeInDays} from '@hconnect/uikit'
import {useMutation} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'

import {api} from '../../../App.global'
import {addJob} from '../../../AsyncJobQueue/AsyncJobQueue.action'
import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestStatuses,
  ApiDownloadRequestTypes,
  JobEndpoint
} from '../../../AsyncJobQueue/AsyncJobQueue.types'
import {AppState} from '../../../Root.store'
import {getFileName, getShortFileName} from '../export.utils'

export const useDownloadRequests = () => {
  const dispatch = useDispatch()
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const {host} = (window as Window).location
  const url = `${RegExp(/localhost/).exec(host) ? 'http' : 'https'}://${host}/downloadRequests`
  return useMutation(
    async (data: DownloadRequestProps) => {
      const {criteria, type, format, jobId} = data
      const {startDate, endDate} = criteria
      const request = {
        analytics: {
          initiationSource: 'hub',
          jobId,
          numberOfDaysSelected: getDateRangeInDays(startDate as string, endDate as string)
        },
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        country: user?.country,
        criteria,
        email: user?.eMail,
        format,
        fullName: user?.name,
        locale: user?.defaultLocale,
        name: getShortFileName(type, startDate as string, endDate as string),
        sendEmail: true,
        type,
        url
      }

      const response = await api.post<DownloadRequestsResponse>('/downloadRequests', request)
      return response.data
    },
    {
      onSuccess: (data: DownloadRequestsResponse, variables: DownloadRequestProps) => {
        const {criteria, jobId, type, format} = variables
        const {startDate, endDate} = criteria
        dispatch(
          addJob({
            id: data.id,
            jobId: jobId,
            status: ApiDownloadRequestStatuses.pending,
            name: getFileName(type, format, criteria),
            type: JobEndpoint.Export,
            fileType: data.format,
            dateRangeInDays: getDateRangeInDays(startDate as string, endDate as string),
            fileSize: undefined,
            requestTimestamp: Date.now(),
            downloadTimeStamp: 0,
            secondsElapsed: 0,
            downloadType: data.type
          })
        )
      }
    }
  )
}

interface DownloadRequestsResponse {
  id: string
  format: string
  type: ApiDownloadRequestTypes
  analytics: {
    jobId: string
    numberOfDaysSelected: string
  }
}

interface DownloadRequestProps {
  criteria: Record<string, string | boolean | undefined>
  type: ApiDownloadRequestTypes
  format: ApiDownloadRequestFormats
  jobId: string
}
