import moment from 'moment'
import {useEffect, useState} from 'react'

import {BUSINESS_HOURS_CANCELLATION_CUT_OFF_TIME_THRESHOLD} from '../../OrderIntake/declarations/constants'
import {OrderCancellationOptions} from '../../OrderIntake/declarations/types'
import {getDayCode, subtractBusinessDays} from '../../Organisms/OrderIntake/utils'
import {Order, ShippingType} from '../../Pages/Order/Order.types'
import {useFeaturesState} from '../../Organisms/Features'

const PLANT_DISTANCE_THRESHOLD = 200

export const useTimeLeftToCancelOrder = (
  order: Order,
  cancellationOptions: OrderCancellationOptions | undefined,
  onCancelCutOffTimeReached: (isTimeToCancelOver: boolean) => void
) => {
  const {getFeature} = useFeaturesState()
  const isOrderWithIncreasedLeadTime = getFeature('OrderIntakeOrderCancellationIncreasedLeadtime')
  const isLongDistanceDelivery = order.distance
    ? order.distance >= PLANT_DISTANCE_THRESHOLD && isOrderWithIncreasedLeadTime
    : false

  const timeZone = moment.tz.guess()
  const today = moment.tz(timeZone).startOf('day')
  const isShippingTypeCollect = order.shippingType === ShippingType.Collect
  /*
  We get the delivery date and the lead time for the delivery day.
  */
  const deliveryDate = moment.tz(order.shippingDate, timeZone)
  const formattedDeliveryDate = deliveryDate.format('DD MMMM YYYY')
  const deliveryExactTime = moment.tz(deliveryDate, timeZone).format('h:mm a')
  const deliveryDateString = deliveryDate.toISOString().split('T')[0]
  const deliveryDayOfWeek = getDayCode(deliveryDateString)
  const deliveryDayLeadTime = cancellationOptions?.businessDays.find(
    (day) => day.dayOfWeek === deliveryDayOfWeek
  )?.cancellationLeadTime
  const deliveryDayLeadTimeForBiggerDistances = deliveryDayLeadTime ? deliveryDayLeadTime + 1 : 0
  const deliveryLeadDate = subtractBusinessDays(
    deliveryDate.clone(),
    isLongDistanceDelivery ? deliveryDayLeadTimeForBiggerDistances : deliveryDayLeadTime,
    cancellationOptions?.businessDays
  )

  /*
  We get the cut off day and the cut off time.
  */
  const cutOffDayString = deliveryLeadDate.toISOString().split('T')[0]
  const formattedCutOffDay = deliveryLeadDate.format('DD MMMM YYYY')
  const cutOffDayOfWeek = getDayCode(cutOffDayString)
  const cutOffTime = cancellationOptions?.businessDays.find(
    (day) => day.dayOfWeek === cutOffDayOfWeek
  )?.cancellationCutOffTime
  const cutOffExactTime = moment.tz(cutOffTime, timeZone).format('h:mm a')
  const cutOffExactDateAndTime = deliveryLeadDate.clone().set({
    hour: moment.tz(cutOffTime, timeZone).hour(),
    minute: moment.tz(cutOffTime, timeZone).minute(),
    seconds: moment.tz(cutOffTime, timeZone).seconds()
  })

  /*
  We calculate the time left to cancel the order.
  */
  const currentTime = moment.tz(timeZone)
  const endTime = moment.tz(cutOffExactDateAndTime, timeZone)
  const cutOffTimeThreshold =
    cancellationOptions?.businessHoursOptions.cutOffTimeThreshold ??
    BUSINESS_HOURS_CANCELLATION_CUT_OFF_TIME_THRESHOLD
  const cutOffTimeThresholdValue =
    moment.duration(cutOffTimeThreshold).hours() * 60 +
    moment.duration(cutOffTimeThreshold).minutes()
  const cutOffTimeThresholdTime = endTime.clone().subtract(cutOffTimeThresholdValue, 'minutes')
  const timeLeft = moment.duration(endTime.unix() - currentTime.unix(), 'seconds')
  const [timer, setTimer] = useState({
    hours: timeLeft.hours(),
    minutes: timeLeft.minutes(),
    seconds: timeLeft.seconds()
  })

  const [isTimeToCancelOver, setIsTimeToCancelOver] = useState<boolean>(
    currentTime.isAfter(endTime)
  )

  if (timer.seconds < 0 && !currentTime.isAfter(endTime)) {
    setTimer({
      hours: timeLeft.hours(),
      minutes: timeLeft.minutes(),
      seconds: timeLeft.seconds()
    })
  }

  const [isTimerActive, setIsTimerActive] = useState<boolean>(false)

  useEffect(() => {
    onCancelCutOffTimeReached(isTimeToCancelOver)
  }, [])

  useEffect(() => {
    if (
      today.isAfter(deliveryLeadDate) ||
      deliveryDayLeadTime === undefined ||
      cancellationOptions === undefined
    ) {
      setIsTimeToCancelOver(true)
      onCancelCutOffTimeReached(isTimeToCancelOver)
      return
    }

    let interval: NodeJS.Timeout
    const runTimer = () => {
      const currentTime = moment.tz(timeZone)
      const timeLeft = moment.duration(endTime.unix() - currentTime.unix(), 'seconds')
      setTimer({
        hours: timeLeft.hours(),
        minutes: timeLeft.minutes(),
        seconds: timeLeft.seconds()
      })
      if (!isShippingTypeCollect && currentTime.isBetween(cutOffTimeThresholdTime, endTime)) {
        setIsTimeToCancelOver(false)
        setIsTimerActive(true)
      } else if (!isShippingTypeCollect && currentTime.isAfter(endTime)) {
        setIsTimeToCancelOver(true)
        setIsTimerActive(false)
        clearInterval(interval)
      } else if (isShippingTypeCollect && today.isAfter(deliveryDate)) {
        setIsTimeToCancelOver(true)
        setIsTimerActive(false)
        clearInterval(interval)
      } else {
        setIsTimeToCancelOver(false)
        setIsTimerActive(false)
        clearInterval(interval)
      }
      onCancelCutOffTimeReached(isTimeToCancelOver)
    }

    runTimer()
    interval = setInterval(() => {
      runTimer()
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [isTimeToCancelOver, onCancelCutOffTimeReached, order])

  return {
    formattedCutOffDay,
    cutOffExactTime,
    isTimeToCancelOver,
    timer,
    isTimerActive,
    formattedDeliveryDate,
    deliveryExactTime
  }
}
