import {Alert, AlertProps, Theme} from '@mui/material'
import {lighten} from '@mui/system'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

interface Type extends AlertProps {
  type: 'info' | 'success' | 'error'
  message: string | React.ReactNode
}

const useStyles = makeStyles()((theme: Theme) => ({
  alert: {
    borderRadius: theme.spacing(0.5),
    background: '#FFFFFF',
    color: '#00274D',
    fontSize: 16,
    lineHeight: '22px',
    boxShadow: 'none',
    '& .MuiAlert-message': {
      width: '100%'
    }
  }
}))

export const AlertWithLeftBorder: React.FC<Type> = ({type, message, ...otherProps}) => {
  const {classes} = useStyles()
  const borderColor = type === 'success' ? '#00AB50' : type === 'error' ? '#DA0901' : '#036ad4'
  const lightBorderColor = lighten(borderColor, 0.85)

  return (
    <Alert
      className={classes.alert}
      elevation={4}
      icon={false}
      data-test-id={`alert-left-${type}`}
      style={{
        border: `1.5px solid ${lightBorderColor}`,
        borderLeft: `4px solid ${borderColor}`,
        backgroundColor: lighten(borderColor, 0.95)
      }}
      {...otherProps}
    >
      {message}
    </Alert>
  )
}
