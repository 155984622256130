import {Box, TextField, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {makeStyles} from 'tss-react/mui'

interface ScheduledExportTitleProps {
  title: string
  disabled: boolean
  error?: string
  onChange: (title: string) => void
  t: TFunction
}

const useStyles = makeStyles()(() => ({
  root: {
    '& .MuiInputBase-root': {
      borderRadius: 4,
      borderColor: '#e7e7e7',
      height: 60
    }
  }
}))

export const ScheduledExportTitle: React.FC<ScheduledExportTitleProps> = ({
  title,
  disabled,
  error,
  onChange,
  t
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }
  const {classes} = useStyles()

  return (
    <Box>
      <TextField
        data-test-id="scheduled-export-title"
        label={t('export.exportTitle')}
        value={title}
        variant="filled"
        onChange={handleChange}
        className={classes.root}
        fullWidth
        error={!!error}
        disabled={disabled}
      />
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </Box>
  )
}
