import {Customer, User} from '@hconnect/apiclient'
import {useSelector} from 'react-redux'

import {ApiDownloadRequestFormats} from '../../../AsyncJobQueue/AsyncJobQueue.types'
import {trackEvent} from '../../../common/analytics'
import {AppState} from '../../../Root.store'

export const useExportAnalytics = () => {
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)

  const track = (eventName: string, customer: Customer, props = {}) => {
    if (!user) return

    const eventValue = {
      ...props,
      product: 'hub',
      userId: user.user_id,
      userCountry: user?.country,
      customerId: customer.customerId,
      customerName: customer.customerName
    }
    trackEvent(eventName, eventValue)
  }

  const trackExportClick = (
    jobId: string,
    customer: Customer,
    entryPoint: string,
    trackingParams: Record<string, string | boolean>
  ) => {
    track('hubExportClick', customer, {
      jobId,
      entryPoint,
      ...trackingParams
    })
  }

  const trackExportStart = (
    jobId: string,
    customer: Customer,
    entryPoint: string,
    format: ApiDownloadRequestFormats
  ) => {
    track('hubExportStart', customer, {
      jobId,
      fileFormat: format === ApiDownloadRequestFormats.xlsx ? 'excel' : 'csv',
      entryPoint
    })
  }

  return {
    trackExportClick,
    trackExportStart
  }
}
