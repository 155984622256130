import {TFunction} from 'i18next'
import {isString} from 'lodash'
import moment from 'moment'

import {
  EXPORT_SHORTNAME_ORDERS,
  EXPORT_SHORTNAME_INVOICES,
  EXPORT_SHORTNAME_MATERIAL_TESTS,
  EXPORT_FILENAME_ORDERS,
  EXPORT_FILENAME_DATEFORMAT,
  EXPORT_FILENAME_INVOICES
} from '../../App.constants'
import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestTypes
} from '../../AsyncJobQueue/AsyncJobQueue.types'

import {ExportFrequency} from './export.types'

const shortDate = (date: string) =>
  (date || new Date().toISOString()).split('T')[0].split('-').join('')
const longDate = (date: string) => moment(date || new Date()).format(EXPORT_FILENAME_DATEFORMAT)

export const getShortFileName = (
  type: ApiDownloadRequestTypes,
  startDate: string,
  endDate: string
) => {
  switch (type) {
    case ApiDownloadRequestTypes.invoiceDeliveryExport:
      return `${EXPORT_SHORTNAME_INVOICES}-${shortDate(startDate)}-${shortDate(endDate)}`
    case ApiDownloadRequestTypes.orderDeliveryExport:
      return `${EXPORT_SHORTNAME_ORDERS}-${shortDate(startDate)}-${shortDate(endDate)}`
    case ApiDownloadRequestTypes.materialTestExport:
      return `${EXPORT_SHORTNAME_MATERIAL_TESTS}-${shortDate(startDate)}-${shortDate(endDate)}`
    default:
      return ''
  }
}

export const getFileName = (
  type: ApiDownloadRequestTypes,
  format: ApiDownloadRequestFormats,
  criteria: Record<string, string | boolean | undefined>
) => {
  let downloadTypePart = ''
  switch (type) {
    case ApiDownloadRequestTypes.invoiceDeliveryExport:
      downloadTypePart = EXPORT_FILENAME_INVOICES
      break
    case ApiDownloadRequestTypes.orderDeliveryExport:
      downloadTypePart = EXPORT_FILENAME_ORDERS
      break
    case ApiDownloadRequestTypes.materialTestExport:
      downloadTypePart = EXPORT_SHORTNAME_MATERIAL_TESTS
      break
    default:
      downloadTypePart = ''
  }

  const {startDate, endDate, orderNumber, invoiceNumber, deliveryNumber} = criteria
  let detailsPart = ''
  if (isString(startDate) && isString(endDate)) {
    detailsPart = `${longDate(startDate)}_-_${longDate(endDate)}`
  } else {
    if (orderNumber) detailsPart = `ORDER_${orderNumber}`
    if (invoiceNumber) detailsPart = `INVOICE_${invoiceNumber}`
    if (deliveryNumber) detailsPart = `DELIVERY_${deliveryNumber}`
  }

  return `${downloadTypePart}_${detailsPart}.${format}`
}

export const getScheduledExportTitle = (
  type: ApiDownloadRequestTypes,
  frequency: ExportFrequency,
  accountName: string,
  t: TFunction
) => {
  const frequencyName = t(`export.frequency.${frequency}`)

  let typeName = ''
  switch (type) {
    case ApiDownloadRequestTypes.invoiceDeliveryExport:
      typeName = t('invoice.title')
      break
    case ApiDownloadRequestTypes.orderDeliveryExport:
      typeName = t('order.title')
      break
    case ApiDownloadRequestTypes.materialTestExport:
      typeName = t('materialTest.testReports')
      break
    default:
      typeName = ''
  }

  return `${frequencyName} - ${accountName} - ${typeName}`
}
