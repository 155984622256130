/* eslint-disable react/jsx-wrap-multilines */
import {Box, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {AppState} from '../../Root.store'

import {selectSelectedResultPerPages} from './index'

const useStyles = makeStyles(() => ({
  button: {
    width: 36,
    border: 'transparent',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      fontWeight: 700,
      color: 'rgb(0,55,77)'
    },
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: 'transparent',
      fontWeight: 700,
      color: 'rgb(0,55,77)'
    }
  },
  buttonGroup: {
    alignItems: 'center'
  }
}))

interface ResultsPerPageSelectorType {
  onClick: (amount: number) => void
}
const ResultsPerPageSelector: React.FC<ResultsPerPageSelectorType> = ({onClick}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const pageAmount = useSelector<AppState, number>((state) => selectSelectedResultPerPages(state))

  const handleResultsPerClick = (event, amount: number) => {
    if (amount !== null && amount !== pageAmount) {
      onClick(amount)
    }
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      data-test-id="results-per-page-selector"
    >
      <Typography variant="body1" color="textPrimary">
        {t('order.resultsPerPage.header')}
      </Typography>
      <ToggleButtonGroup
        classes={{root: classes.buttonGroup}}
        value={pageAmount}
        exclusive
        onChange={handleResultsPerClick}
        aria-label="result amount selector"
      >
        {' '}
        <ToggleButton value={10} aria-label="10 results" classes={{root: classes.button}}>
          {10}
        </ToggleButton>
        <ToggleButton
          value={50}
          aria-label="50 results"
          classes={{root: classes.button}}
          data-test-id="results-per-page-btn-50"
        >
          {50}
        </ToggleButton>
        <ToggleButton value={100} aria-label="100 results" classes={{root: classes.button}}>
          {100}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default ResultsPerPageSelector
