import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export const useMultiSelectDropdownStyles = makeStyles()((theme: Theme) => ({
  multiSelectBox: {
    minHeight: '60px',
    height: '100%',
    width: '200px',
    background: '#f7f7f7',
    borderRadius: '4px',

    fieldset: {
      border: '1px solid #e7e7e7 !important'
    },
    '&:hover': {
      background: '#e7e7e7',
      borderColor: '#eeeeee !important'
    },
    '& > div:first-of-type': {
      paddingBottom: '4px',
      '&.MuiSelect-select:focus': {
        background: 'transparent'
      }
    }
  },
  placeholder: {
    fontSize: '16px',
    lineHeight: '16px',
    color: '#6a8492'
  },
  statusItems: {
    padding: theme.spacing(1.5),
    background: '#f7f7f7',
    borderBottom: '1px solid #E0E0E0',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.07) !important'
    }
  },
  statusLabel: {
    fontSize: '14px',
    lineHeight: '18px',

    '&.MuiInputLabel-outlined': {
      transform: 'translate(18px, 18px) scale(1)'
    },
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, 6px) scale(0.85)'
    }
  },
  defaultValue: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  defaultValueCount: {
    color: '#6a8492',
    fontSize: '14px'
  },
  checkbox: {
    svg: {
      fill: '#809ba6',
      height: '18px',
      width: '18px'
    }
  }
}))
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: 0,
      backgroundColor: '#f5f5f5'
    }
  }
}
