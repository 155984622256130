import {User} from '@hconnect/apiclient'
import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {useSelector} from 'react-redux'

import {api} from '../../../App.global'
import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestTypes
} from '../../../AsyncJobQueue/AsyncJobQueue.types'
import {AppState} from '../../../Root.store'
import {ExportFrequency, ScheduledExport} from '../export.types'

export const useScheduledExports = () => {
  const queryClient = useQueryClient()
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const {host} = (window as Window).location
  const url = `${RegExp(/localhost/).exec(host) ? 'http' : 'https'}://${host}/downloadRequests`
  return useMutation<ScheduledExport, AxiosError, ScheduledExportsProps>(
    async (data: ScheduledExportsProps) => {
      const {criteria, downloadType, format, frequency, title} = data
      delete criteria.startDate
      delete criteria.endDate
      const request = {
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        country: user?.country,
        criteria,
        downloadType,
        format,
        frequency,
        locale: user?.defaultLocale,
        title: title,
        url
      }

      const response = await api.post<ScheduledExport>('/scheduledExports', request)
      return response.data
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries('scheduledExports')
      }
    }
  )
}

interface ScheduledExportsProps {
  criteria: Record<string, string | boolean | undefined>
  downloadType: ApiDownloadRequestTypes
  format: ApiDownloadRequestFormats
  frequency: ExportFrequency
  title: string
}
