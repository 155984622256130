import {TFunction} from 'i18next'

import {SelectDropdown} from '../../SelectDropdown/SelectDropdown'
import {ExportFrequency} from '../export.types'

interface ExportFrequencyDropdownProps {
  frequency: ExportFrequency
  disabled: boolean
  onChange: (frequency: ExportFrequency) => void
  t: TFunction
  oneTimeExportDisabled?: boolean
}

export const ExportFrequencyDropdown: React.FC<ExportFrequencyDropdownProps> = ({
  frequency,
  disabled,
  onChange,
  t,
  oneTimeExportDisabled = false
}) => {
  const options = !oneTimeExportDisabled
    ? Object.values(ExportFrequency)
    : [ExportFrequency.daily, ExportFrequency.weekly, ExportFrequency.monthly]
  return (
    <SelectDropdown
      data-test-id="export-frequency-dropdown"
      onChange={onChange}
      showSearch={false}
      label={t('export.exportFrequency')}
      options={options}
      renderItem={(item: ExportFrequency) => t(`export.frequency.${item}`)}
      stringifyItem={(item: ExportFrequency) => t(`export.frequency.${item}`)}
      selectedItem={frequency}
      onLight={true}
      popoverStyle={{left: 0, top: 0}}
      titleButtonStyle={{width: '100%', maxWidth: 360}}
      disabled={disabled}
    />
  )
}
