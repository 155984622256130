export enum ExportFrequency {
  oneTime = 'oneTime',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly'
}

export interface ScheduledExport {
  id: string
  nextRunTime: string
  frequency: ExportFrequency
  title: string
}
