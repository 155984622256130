import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import {Button} from '@mui/material'
import {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {v4 as uuidV4} from 'uuid'

import {ApiDownloadRequestTypes} from '../../AsyncJobQueue/AsyncJobQueue.types'
import {Customer} from '../../Organisms/Customers/Customers.types'

import {ScheduledExport} from './export.types'
import {ExportDialog} from './ExportDialog'
import {useExportAnalytics} from './hooks'

interface ExportDropdownProps {
  dataTestId?: string
  disabled?: boolean
  oneTimeExportDisabled?: boolean
  downloadType: ApiDownloadRequestTypes
  getCriteria: () => Record<string, string | boolean | undefined>
  onExportScheduled: (scheduledExport: ScheduledExport) => void
  entryPoint: string
  trackingParams: Record<string, string | boolean>
  customer: Customer
}

export const ExportDropdown: React.FC<ExportDropdownProps> = ({
  dataTestId,
  disabled,
  oneTimeExportDisabled,
  downloadType,
  getCriteria,
  onExportScheduled,
  entryPoint,
  trackingParams,
  customer
}) => {
  const {t} = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [exportJobId, setExportJobId] = useState<string | undefined>(undefined)
  const {trackExportClick} = useExportAnalytics()

  const anchorEl = useRef<HTMLButtonElement | null>(null)

  const openDialog = () => {
    const jobIdTemp = uuidV4()
    setExportJobId(jobIdTemp)
    trackExportClick(jobIdTemp, customer, entryPoint, trackingParams)
    setIsDialogOpen(true)
  }

  const closeDialog = () => {
    setExportJobId(undefined)
    setIsDialogOpen(false)
  }

  return (
    <>
      <Button
        data-test-id={dataTestId}
        startIcon={<TextSnippetOutlinedIcon />}
        disabled={disabled}
        variant="text"
        onClick={openDialog}
        ref={anchorEl}
      >
        {t('export.buttonTitle')}
      </Button>
      <ExportDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onExportScheduled={onExportScheduled}
        anchorEl={anchorEl.current}
        downloadType={downloadType}
        getCriteria={getCriteria}
        jobId={exportJobId || ''}
        customer={customer}
        entryPoint={entryPoint}
        oneTimeExportDisabled={oneTimeExportDisabled}
        t={t}
      />
    </>
  )
}
