import {TFunction} from 'i18next'

import {ApiDownloadRequestFormats} from '../../../AsyncJobQueue/AsyncJobQueue.types'
import {SelectDropdown} from '../../SelectDropdown/SelectDropdown'

interface ExportFormatDropdownProps {
  format: ApiDownloadRequestFormats
  disabled: boolean
  onChange: (format: ApiDownloadRequestFormats) => void
  t: TFunction
}

export const ExportFormatDropdown: React.FC<ExportFormatDropdownProps> = ({
  format,
  disabled,
  onChange,
  t
}) => {
  const options = [ApiDownloadRequestFormats.xlsx, ApiDownloadRequestFormats.csv]
  return (
    <SelectDropdown
      data-test-id="export-format-dropdown"
      onChange={onChange}
      showSearch={false}
      label={t('export.exportFormat')}
      options={options}
      renderItem={(item: ApiDownloadRequestFormats) => t(`export.format.${item}`)}
      stringifyItem={(item: ApiDownloadRequestFormats) => t(`export.format.${item}`)}
      selectedItem={format}
      onLight={true}
      popoverStyle={{left: 0, top: 0}}
      titleButtonStyle={{width: '100%', maxWidth: 360}}
      disabled={disabled}
    />
  )
}
