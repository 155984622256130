import React, {useMemo} from 'react'
import {FilterDefinition} from '../../common/types'
import {usePersistFilters} from '../../common/react-query/hooks/mutations/usePersistFilters'
import {usePersistedFilters} from '../../common/react-query/hooks/queries/usePersistedFilters'

export const useFilters = (
  filterType: string,
  filters: FilterDefinition[],
  onResetFilter?: (filter: FilterDefinition) => void
) => {
  const [filtersDefinition, setFiltersDefinition] = React.useState<FilterDefinition[]>(filters)
  const getFilterValue = useMemo(
    () => (filterName: string) => {
      const filter = filtersDefinition.find((filter) => filter.name === filterName)
      return filter && filter?.isActive
    },
    [filtersDefinition]
  )

  const {mutate: persistFilters} = usePersistFilters()
  const {data: persistedFilters} = usePersistedFilters(filterType)

  //merge persisted filters with initial filters
  React.useEffect(() => {
    const filters = [...filtersDefinition]

    if (persistedFilters) {
      filters.forEach((filter) => {
        const persistedFilter = persistedFilters.find(
          (persistedFilter) => persistedFilter.name === filter.name
        )
        if (persistedFilter) {
          filter.isActive = persistedFilter.isActive
        }
      })

      setFiltersDefinition(filters)
    }
  }, [persistedFilters])

  const handleOnFiltersChange = (filters: FilterDefinition[]) => {
    filtersDefinition.forEach((filter) => {
      const newFilter = filters.find((newFilter) => newFilter.name === filter.name)
      if (newFilter && filter.isActive && !newFilter.isActive) {
        onResetFilter && onResetFilter(filter)
      }
    })

    setFiltersDefinition(filters)
    persistFilters({filterType, filters})
  }

  return {filtersDefinition, getFilterValue, handleOnFiltersChange, onResetFilter}
}
