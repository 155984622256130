import CloseIcon from '@mui/icons-material/Close'
import EventIcon from '@mui/icons-material/Event'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import {Box, Button, Popover, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestTypes
} from '../../AsyncJobQueue/AsyncJobQueue.types'
import {Customer} from '../../Organisms/Customers/Customers.types'
import {ROUTE as NotificationSettingsRoute} from '../../Pages/NotificationSettings'
import {AlertWithLeftBorder} from '../Alert/AlertWithLeftBorder'

import {ExportFormatDropdown, ExportFrequencyDropdown, ScheduledExportTitle} from './components'
import {ExportFrequency, ScheduledExport} from './export.types'
import {getScheduledExportTitle} from './export.utils'
import {useDownloadRequests, useExportAnalytics, useScheduledExports} from './hooks'

interface ExportDialogProps {
  open: boolean
  downloadType: ApiDownloadRequestTypes
  getCriteria: () => Record<string, string | boolean | undefined>
  jobId: string
  entryPoint: string
  customer: Customer
  anchorEl: HTMLElement | null
  t: TFunction
  onClose: () => void
  onExportScheduled: (scheduledExport: ScheduledExport) => void
  oneTimeExportDisabled?: boolean
}

export const ExportDialog: React.FC<ExportDialogProps> = ({
  open,
  downloadType,
  getCriteria,
  jobId,
  entryPoint,
  customer,
  anchorEl,
  t,
  onClose,
  onExportScheduled,
  oneTimeExportDisabled
}) => {
  const [frequency, setFrequency] = useState<ExportFrequency>(
    oneTimeExportDisabled ? ExportFrequency.daily : ExportFrequency.oneTime
  )
  useEffect(() => {
    setFrequency(oneTimeExportDisabled ? ExportFrequency.daily : ExportFrequency.oneTime)
  }, [oneTimeExportDisabled])
  const [format, setFormat] = useState<ApiDownloadRequestFormats>(ApiDownloadRequestFormats.xlsx)
  const [title, setTitle] = useState<string>(
    getScheduledExportTitle(downloadType, frequency, customer.customerName, t)
  )
  const [isTitleValid, setIsTitleValid] = useState(true)
  const isOneTime = frequency === ExportFrequency.oneTime
  const {trackExportStart} = useExportAnalytics()
  const downloadRequests = useDownloadRequests()
  const scheduledExports = useScheduledExports()

  const isLoading = downloadRequests.isLoading || scheduledExports.isLoading
  const isError = downloadRequests.isError || scheduledExports.isError
  const isDuplicateError =
    scheduledExports.isError && scheduledExports.error.response?.status === 409
  useEffect(() => {
    if (downloadRequests.isSuccess) {
      downloadRequests.reset()
      onClose()
    }
  }, [downloadRequests, onClose])

  useEffect(() => {
    if (scheduledExports.isSuccess) {
      onClose()
      onExportScheduled(scheduledExports.data)
      scheduledExports.reset()
    }
  }, [scheduledExports, onClose, onExportScheduled])

  useEffect(() => {
    setTitle(getScheduledExportTitle(downloadType, frequency, customer.customerName, t))
  }, [downloadType, frequency, customer.customerName, t])

  const handleExport = () => {
    downloadRequests.mutate({
      criteria: getCriteria(),
      type: downloadType,
      format,
      jobId
    })
    trackExportStart(jobId, customer, entryPoint, format)
  }

  const handleScheduleExport = () => {
    if (!isTitleValid) return
    scheduledExports.mutate({
      criteria: getCriteria(),
      downloadType,
      format,
      frequency,
      title
    })
  }

  const handleTitleChange = (newTitle: string) => {
    setTitle(newTitle)
    setIsTitleValid(newTitle.length > 0 && newTitle.length <= 255)
  }

  const alertMessage = isDuplicateError ? (
    <>
      <Typography> {t('export.scheduledExportDuplicateError')}</Typography>
      <br />
      <Link to={NotificationSettingsRoute}>{t('export.subscriptionSettings')}</Link>
    </>
  ) : isError ? (
    t('errorboundary.error')
  ) : !isOneTime ? (
    t(`export.scheduledExportInfo.${frequency}`)
  ) : undefined

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Box
        data-test-id="export-dialog"
        sx={{maxWidth: 360, padding: 2, display: 'flex', flexDirection: 'column', gap: 2}}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{t('export.exportConfiguration')}</Typography>
          <Button
            data-test-id="export-dialog-close"
            onClick={onClose}
            variant="text"
            size="small"
            color="secondary"
          >
            <CloseIcon />
          </Button>
        </Box>
        <Typography variant="body2">{t('export.exportDescription')}</Typography>
        <ExportFrequencyDropdown
          frequency={frequency}
          disabled={isLoading}
          onChange={setFrequency}
          t={t}
          oneTimeExportDisabled={oneTimeExportDisabled}
        />
        <ExportFormatDropdown format={format} disabled={isLoading} onChange={setFormat} t={t} />
        {!isOneTime && (
          <ScheduledExportTitle
            title={title}
            disabled={isLoading}
            error={!isTitleValid ? t('export.titleError') : undefined}
            onChange={handleTitleChange}
            t={t}
          />
        )}
        <Button
          data-test-id="export-button"
          onClick={isOneTime ? handleExport : handleScheduleExport}
          variant="contained"
          startIcon={isOneTime ? <SaveAltIcon /> : <EventIcon />}
          disabled={isLoading || !isTitleValid}
          sx={{
            height: 48,
            minWidth: 171,
            width: 'fit-content',
            alignSelf: 'flex-end'
          }}
        >
          {isOneTime ? t('export.exportNow') : t('export.scheduleExport')}
        </Button>
        {alertMessage && <AlertWithLeftBorder type="info" message={alertMessage} />}
      </Box>
    </Popover>
  )
}
