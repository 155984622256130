import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {TitleButton} from './TitleButton'

import useDropdownStyle from './DropdownStyles'
import {LoadingState} from './LoadingState'
import {DropdownContent} from './DropdownContent'
import {DropDownProps} from './types'

export const SelectDropdown = ({
  label,
  noSelectionLabel,
  options = [],
  loading,
  disabled,
  highlighted,
  errorRender = () => 'Something went wrong',
  showError = false,
  stringifyItem,
  stringifySubItem,
  renderChosenItem,
  renderItem,
  renderSubItem,
  onChange = () => undefined,
  onSearchTermChange = () => undefined,
  selectedItem,
  identifierKey,
  selectedProps = {},
  showSearch = true,
  className: classNameProp,
  dark = false,
  long = false,
  onLight = false,
  isDisabled,
  SubItemIcon,
  'data-test-id': dataTestId,
  subLabel,
  inWidget = false,
  popoverStyle,
  titleButtonStyle,
  smallScreen = false,
  multiSelect = false
}: DropDownProps) => {
  const {classes} = useDropdownStyle()
  const {t} = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [open, setOpen] = useState(false)
  const anchorEl = useRef()
  useEffect(() => {
    onSearchTermChange(searchTerm)
  }, [searchTerm])

  if (loading)
    return (
      <LoadingState
        popoverStyle={popoverStyle}
        label={label}
        classNameProp={classNameProp}
        dark={dark}
        long={long}
        anchorEl={anchorEl}
        inWidget={inWidget}
        onLight={onLight}
        selectedProps={selectedProps}
        open={open}
        setOpen={setOpen}
        t={t}
        highlighted={highlighted}
      />
    )

  const stringifiedLabel = (
    selected: any,
    strItem: (item: any) => string,
    strSubItem?: (item: any) => string
  ) => {
    const splittedLiteral = strItem(selected).split(' ')

    if (strSubItem && splittedLiteral.includes('undefined')) {
      return strSubItem(selected)
    }
    return strItem(selected)
  }
  const renderLabel = (
    selected: any,
    strItem: (item: any) => string,
    strSubItem?: (item: any) => string,
    renderChosenItem?: (item: any) => string
  ) => {
    if (renderChosenItem) {
      return renderChosenItem(selected)
    }
    return stringifiedLabel(selected, strItem, strSubItem)
  }
  const selectedLabel = selectedItem
    ? renderLabel(selectedItem, stringifyItem, stringifySubItem, renderChosenItem)
    : noSelectionLabel

  const selectedSubLabel = selectedItem && subLabel ? subLabel(selectedItem) : null

  return (
    <div
      className={`${classes.mobileRoot} ${disabled ? classes.disabled : ''}`}
      aria-label={`${label} dropdown`}
    >
      <TitleButton
        data-test-id={dataTestId}
        className={`${classes.button} ${dark ? classes.dark : ''} ${classNameProp}`}
        long={long}
        dark={dark}
        highlighted={highlighted}
        buttonRef={anchorEl}
        onClick={() => setOpen((value) => !value)}
        selectedSubLabel={selectedSubLabel}
        selectedLabel={selectedLabel}
        label={label}
        smallScreen={smallScreen}
        isLoading={loading}
        style={titleButtonStyle}
      />
      <DropdownContent
        popoverStyle={popoverStyle}
        label={label}
        stringifySubItem={stringifySubItem}
        stringifyItem={stringifyItem}
        dark={dark}
        anchorEl={anchorEl}
        showError={showError}
        showSearch={showSearch}
        noSelectionLabel={noSelectionLabel}
        errorRender={errorRender}
        onChange={onChange}
        options={options}
        SubItemIcon={SubItemIcon}
        onLight={onLight}
        t={t}
        renderItem={renderItem}
        renderSubItem={renderSubItem}
        dataTestId={dataTestId}
        selectedItem={selectedItem}
        identifierKey={identifierKey}
        open={open}
        isDisabled={isDisabled}
        setOpen={setOpen}
        multiSelect={multiSelect}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedProps={selectedProps}
      />
    </div>
  )
}
