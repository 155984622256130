import {FilterBar, FilterBarProps} from '@hconnect/uikit'
import {Overlay} from '@hconnect/uikit/src/lib/Shell/Overlay'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import FilterIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import TuneIcon from '@mui/icons-material/Tune'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import {find} from 'lodash'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {makeStyles} from 'tss-react/mui'

import {FilterDefinition} from '../../common/types'
import {ResponsiveWidth} from '../../Molecules/Responsive.utils'
import {useSMFilterStyles} from '../../Molecules/SMFilter/SMFilterStyles'
import {LookupSelectItems} from '../../Pages/Order/Order.enum'
import {removeLookupFilter} from '../../Pages/Order/Order.filters'
import {usePermissions} from '../../Permissions'
import {AppState} from '../../Root.store'
import {selectCustomers} from '../Customers'
import {CustomerStateType} from '../Customers/Action.types'
import {Customer} from '../Customers/Customers.types'

export const useStyles = makeStyles()((theme) => ({
  button: {
    padding: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: '#f7f7f7 !important',
    border: '1px solid #e7e7e7',
    '&:hover': {
      background: '#e7e7e7 !important',
      borderColor: '#eeeeee',
      color: '#00274d !important'
    },
    color: '#00274d !important',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px'
  },
  filterBarContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2)
  }
}))

interface GenericFilterBarError {
  key: string
  label: string
}

interface Props {
  lookupFilter?: React.ReactNode
  customerSearchByNameEnabled?: boolean
  errors?: GenericFilterBarError[]
  filters?: FilterDefinition[]
  xs?: boolean
  isVisibleSearchLookupForMobile?: boolean
  isVisible?: boolean
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
  numberOfItems?: number
  apply?: () => void
  onFiltersChange?: (filters: FilterDefinition[]) => void
  onSearch?: ({entity, value, label}: {entity: any; value: any; label: any}) => void
  toolbarProps?: any
  children?: React.ReactNode
  showErrorAsInfo?: boolean
}

// eslint-disable-next-line complexity
export const GenericFilterBar: React.FC<Props> = ({
  lookupFilter,
  xs = false,
  customerSearchByNameEnabled = false,
  errors = [],
  filters = [],
  isVisible,
  isVisibleSearchLookupForMobile,
  setIsVisible,
  numberOfItems,
  apply,
  onFiltersChange,
  onSearch,
  toolbarProps,
  children,
  ...props
}) => {
  const {t} = useTranslation()
  const {displayCustomerSimpleLookup} = usePermissions()

  const customersState: CustomerStateType = useSelector<AppState, CustomerStateType>(
    (state: AppState) => selectCustomers(state)
  )
  const {customers, selectedCustomer, searchString} = customersState

  const isFetching = useSelector<AppState, boolean>((state) => selectCustomers(state).isFetching)

  const classes = useSMFilterStyles()
  const {
    classes: {button, filterBarContent}
  } = useStyles()
  const dispatch = useDispatch()
  const close = () => {
    setIsVisible && setIsVisible(false)
  }

  const [searchValue, setSearchValue] = React.useState('')
  const [searchSelected, setSearchSelected] = React.useState<boolean>(false)

  const [anchorFilterEl, setAnchorFilterEl] = useState<HTMLElement | null>(null)
  const openFilter = Boolean(anchorFilterEl)

  const handleFilterClose = () => {
    setAnchorFilterEl(null)
  }

  const handleShowFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilterEl(event.currentTarget)
  }

  const handleFilterChange = (filter?: FilterDefinition) => {
    const updatedFilters = filters.map((item) =>
      item.name === filter?.name ? {...item, isActive: !item.isActive} : item
    )
    onFiltersChange && onFiltersChange(updatedFilters)
  }

  // FIXME: add type for error
  const customerNumberFetchError = useSelector<AppState, any>(
    (state) => selectCustomers(state).error
  )

  const customerSearchString = useSelector<AppState, string>(
    (state) => selectCustomers(state).searchString
  )

  const customer: Customer | undefined = selectedCustomer
    ? find(customers, {customerId: selectedCustomer.customerId})
    : customers[0]

  const filterBarErrors: GenericFilterBarError[] = [...errors]
  if (!customer && displayCustomerSimpleLookup && !isFetching) {
    if (customerNumberFetchError === null && !searchString) {
      customerSearchByNameEnabled && customers?.length === 0
        ? filterBarErrors.push({
            key: 'errorNoSearchCustomerSelected',
            label: t('filterBar.errorNoSearchCustomerSelected')
          })
        : filterBarErrors.push({
            key: 'errorNoCustomerSelected',
            label: t('filterBar.errorNoCustomerSelected')
          })
    } else if (
      customerSearchByNameEnabled &&
      customers &&
      customers.length === 0 &&
      !!searchString
    ) {
      filterBarErrors.push({
        key: 'errorCustomerResponseEmpty',
        label: t('filterBar.errorCustomerResponseEmpty')
      })
    } else {
      filterBarErrors.push({
        key: 'errorCustomerNotFound',
        label: t('filterBar.errorCustomerNotFound', {CUSTOMER_NUMBER: customerSearchString})
      })
    }
  }

  const filterSelectMenu = (
    <Menu anchorEl={anchorFilterEl} open={openFilter} onClose={handleFilterClose}>
      {filters.map((filter, index) => (
        <MenuItem
          key={index}
          data-test-id={`filter-menu-item-${filter.name}`}
          onClick={() => {
            !filter.isMandatory && handleFilterChange(filter)
          }}
        >
          <Checkbox
            disableRipple
            color="primary"
            disabled={filter.isMandatory}
            checked={filter.isActive}
          />
          <Typography style={{fontWeight: 500, color: '#00274D'}}>{filter.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  )

  const lookupFilterRef = React.useRef<HTMLDivElement | null>(null)

  if (xs) {
    if (!isVisible) {
      return (
        <>
          <Box
            sx={{zIndex: 10000}}
            data-test-id="sm-filter-closed"
            width="100%"
            display="flex"
            flexDirection="row"
            px={2}
            py={1}
          >
            <Box flex="1" height="fit-content" alignSelf="center">
              {searchSelected ? (
                <InputBase
                  autoFocus
                  placeholder={t('order.lookup.orderPlchld')}
                  inputProps={{'aria-label': 'search with number'}}
                  onChange={(event) => setSearchValue(event.target.value)}
                  sx={{width: '100%', color: 'white'}}
                />
              ) : (
                <Typography variant="h3" color="white">
                  {t('filterMenu.itemsListed', {numberOfItems})}
                </Typography>
              )}
            </Box>

            {isVisibleSearchLookupForMobile && onSearch ? (
              <IconButton
                data-test-id="enable-search-button"
                type="button"
                color="secondary"
                style={{border: 0, boxShadow: 'none'}}
                aria-label="search"
                disableRipple
                disableFocusRipple
                onClick={() => {
                  searchSelected
                    ? onSearch &&
                      Boolean(searchValue.trim()) &&
                      onSearch({
                        entity: LookupSelectItems.ORDER,
                        label: '',
                        value: searchValue.trim()
                      })
                    : setSearchSelected(true)
                }}
              >
                <SearchIcon fontSize="large" />
              </IconButton>
            ) : null}
            {!searchSelected ? (
              <IconButton
                aria-label="filter"
                onClick={() => {
                  setIsVisible && setIsVisible(true)
                }}
                style={{border: 0, boxShadow: 'none', color: 'white'}}
                disableRipple
                disableFocusRipple
              >
                <FilterIcon fontSize="large" />
              </IconButton>
            ) : (
              <IconButton
                style={{border: 0, boxShadow: 'none'}}
                aria-label="filter"
                onClick={() => {
                  setSearchSelected(false)
                  dispatch(removeLookupFilter())
                }}
                disableRipple
                disableFocusRipple
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
          <Divider variant="middle" />
        </>
      )
    }

    return (
      <Overlay
        data-test-id="sm-filter-opened"
        isVisible={isVisible}
        overlayClassName={classes.overlay}
        contentClassName={classes.overlayContent}
      >
        <Box
          bgcolor="background.paper"
          color="text.primary"
          sx={{
            mt: 1,
            p: 4,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'sticky',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Box display={'flex'} alignItems={'center'}>
                <Typography variant="h3">{t('filterMenu.filter')}</Typography>
              </Box>

              <Box display={'flex'} alignItems={'center'}>
                <Button
                  sx={{
                    height: '60px',
                    width: '60px',
                    minWidth: '60px',
                    marginRight: '10px'
                  }}
                  className={button}
                  onClick={handleShowFilterClick}
                >
                  <TuneIcon />
                </Button>

                <IconButton className={classes.closeIcon} onClick={() => close()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            <Box mt={2} className={classes.filter}>
              {children}
            </Box>
          </Box>
          <Box mb={10}>
            <Button
              className={classes.applyButton}
              variant="contained"
              startIcon={<ArrowForwardIcon />}
              onClick={() => {
                apply && apply()
                close()
              }}
            >
              {t('filterMenu.applyFilter')}
            </Button>
          </Box>
        </Box>
        {filterSelectMenu}
      </Overlay>
    )
  }

  return (
    <Box
      sx={{
        width: ResponsiveWidth
      }}
    >
      <FilterBar
        isLoading={isFetching}
        errors={filterBarErrors}
        toolbarProps={{color: 'transparent', className: classes.toolbar, ...toolbarProps}}
        {...props}
      >
        <Box className={filterBarContent}>
          {filters && filters.length > 0 && (
            <Button
              sx={{
                height: '60px',
                minWidth: '110px'
              }}
              className={button}
              startIcon={<TuneIcon />}
              onClick={handleShowFilterClick}
              data-test-id="filter-menu-filters"
            >
              {t('filterMenu.filters')}
            </Button>
          )}
          {filterSelectMenu}
          {children}
        </Box>
        {lookupFilter && (
          <Box width={400} height={60} ml="auto" ref={lookupFilterRef}>
            {lookupFilter}
          </Box>
        )}
      </FilterBar>
    </Box>
  )
}
