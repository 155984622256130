import CloseIcon from '@mui/icons-material/Close'
import {Box, Button, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {ROUTE as NotificationSettingsRoute} from '../../Pages/NotificationSettings'
import {AlertWithLeftBorder} from '../Alert/AlertWithLeftBorder'

import {ScheduledExport} from './export.types'

interface ExportScheduledInfoProps {
  scheduledExport?: ScheduledExport
}

export const ExportScheduledInfo: React.FC<ExportScheduledInfoProps> = ({scheduledExport}) => {
  const {t} = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  useEffect(() => {
    if (scheduledExport) {
      setIsOpen(true)
    }
  }, [scheduledExport])

  const goToSettings = () => {
    history.push(NotificationSettingsRoute)
  }

  if (!isOpen || !scheduledExport) return null

  return (
    <AlertWithLeftBorder
      data-test-id="export-scheduled-info"
      type="success"
      sx={{m: 2}}
      message={
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography sx={{flexGrow: 1}}>
            {t('export.scheduledExportSuccess', {title: scheduledExport.title})}
          </Typography>
          <Button onClick={goToSettings} variant="text">
            {t('export.subscriptionSettings')}
          </Button>
          <Button
            data-test-id="export-scheduled-info-close"
            onClick={() => setIsOpen(false)}
            variant="text"
            size="small"
            color="secondary"
          >
            <CloseIcon />
          </Button>
        </Box>
      }
    />
  )
}
